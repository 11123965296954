<template>
  <div>
    <b-modal
      v-model="showModalUpload"
      :width="500"
      :canClose="['outside']"
      scroll="clip"
      :on-cancel="closeModalUpload"
    >
      <header style="border-radius:0px" class="modal-card-head">
        <span class="tetx-base">Form Upload</span>
      </header>
      <div class="card" style="width: auto;border-radius:0px">
        <div
          class="bg-white pb-5 pt-3 flex justify-start items-center flex-col"
          style="width:auto;"
        >
          <div class="mt-5">
            <b-upload v-model="file" class="p-0" drag-drop>
              <section class="section py-5 px-5">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-medium"> </b-icon>
                  </p>
                  <span v-if="!file" class="text-sm">
                    Tarik File Di Sini Atau Klik Untuk Pilih</span
                  >
                  <span v-if="file" class="text-sm">
                    File Terpilih : (
                    {{ file ? file.name : "" }} )
                    <br />
                    Tarik File Di Sini Atau Klik Untuk Pilih Ulang
                  </span>
                </div>
              </section>
            </b-upload>
          </div>
          <div
            style="max-width: 430px"
            class="flex flex-row justify-between w-full items-center mt-5"
          >
            <b-button
              @click="closeModalUpload"
              class="is-light"
              style="width:49%"
              >Batal</b-button
            >
            <b-button
              @click="onUploadInstallment"
              type="is-link"
              style="width:49%"
              >Simpan</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="showModalConfirmation"
      :width="800"
      :canClose="['outside']"
      scroll="clip"
      :on-cancel="closeModalConfirmation"
    >
      <header style="border-radius:0px" class="modal-card-head">
        <div class="flex justify-between items-center w-full flex-row">
          <span class="tetx-base">Update</span>
          <b-button class="pa-2" @click="onConfirm" type="is-link"
            >Konfirmasi</b-button
          >
        </div>
      </header>
      <div class="card" style="width: auto;border-radius:0px">
        <div
          class="bg-white pb-5 pt-4 flex justify-start items-center flex-col"
          style="width:auto;"
        >
          <div
            class="w-full grid grid-cols-1 sm:grid-cols-2 md:lg-grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
          >
            <div class="flex flex-col items-center">
              <div
                style="max-width: 210px"
                class="flex flex-row flex-wrap justify-between w-full items-center my-1.5"
              >
                <span class="text-xs">No.Kontrak :</span>
                <span class="text-xs">{{
                  confirmationData ? confirmationData.loanAccountNumber : "-"
                }}</span>
              </div>
              <div
                style="max-width: 210px"
                class="flex flex-row flex-wrap justify-between w-full items-center my-1.5"
              >
                <span class="text-xs">Nama Nasabah :</span>
                <span class="text-xs">{{
                  confirmationData ? confirmationData.cifName : "-"
                }}</span>
              </div>
            </div>
            <div class="flex flex-col items-center">
              <div
                style="max-width: 210px"
                class="flex flex-row flex-wrap justify-between w-full items-center my-1.5"
              >
                <span class="text-xs">Total Outstanding Baru :</span>
                <span class="text-xs">{{
                  confirmationData
                    ? convertDecimalCurrency(
                        confirmationData.totalNewOutstanding
                      )
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
          <b-table
            class="mt-4"
            narrowed
            v-if="confirmationData"
            :data="confirmationData.modifyInstallmentDatas"
          >
            <template v-slot:empty>
              <div class="flex justify-center items-center p-3">
                <span class="loan-installment-upload-form-font">
                  No Data Found
                </span>
              </div>
            </template>
            <b-table-column centered field="noUrut" label="Urut">
              <template v-slot:header="{ column }">
                <span class="loan-installment-upload-form-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="loan-installment-upload-form-font">{{
                  props.row.noSeq
                }}</span>
              </template>
            </b-table-column>
            <b-table-column centered field="principal" label="Nominal Pokok">
              <template v-slot:header="{ column }">
                <span class="loan-installment-upload-form-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="loan-installment-upload-form-font">{{
                  props.row.principal
                    ? convertDecimalCurrency(props.row.principal)
                    : props.row.principal
                }}</span>
              </template>
            </b-table-column>
            <b-table-column centered field="principalRemain" label="Sisa Pokok">
              <template v-slot:header="{ column }">
                <span class="loan-installment-upload-form-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="loan-installment-upload-form-font">{{
                  props.row.principalRemain
                    ? convertDecimalCurrency(props.row.principalRemain)
                    : props.row.principalRemain
                }}</span>
              </template>
            </b-table-column>
            <b-table-column centered field="margin" label="Margin">
              <template v-slot:header="{ column }">
                <span class="loan-installment-upload-form-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="loan-installment-upload-form-font">{{
                  props.row.margin
                    ? convertDecimalCurrency(props.row.margin)
                    : props.row.margin
                }}</span>
              </template>
            </b-table-column>
            <b-table-column
              centered
              field="installment"
              label="Nominal Angsuran"
            >
              <template v-slot:header="{ column }">
                <span class="loan-installment-upload-form-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="loan-installment-upload-form-font">{{
                  props.row.installment
                    ? convertDecimalCurrency(props.row.installment)
                    : props.row.installment
                }}</span>
              </template>
            </b-table-column>
            <b-table-column centered field="dueDate" label="Tgl. Jatuh Tempo">
              <template v-slot:header="{ column }">
                <span class="loan-installment-upload-form-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="loan-installment-upload-form-font">{{
                  props.row.dueDate
                    ? dateToMonthText(props.row.dueDate)
                    : props.row.dueDate
                }}</span>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import data1 from "@/views/reports/history-angsuran-recondition/detail"

export default {
  name: "LoanInstallmentReconditionUploadForm",
  data() {
    return {
      data1: data1,
      file: null,
      isLoadingUpload: false,
      isLoadingSubmit: false,
      confirmationData: null,
      showModalUpload: false,
      showModalConfirmation: false,
    };
  },
  methods: {
    onOpenModal() {
      this.showModalUpload = true;
    },
    closeModalUpload() {
      this.showModalUpload = false;
      this.file = null;
    },
    closeModalConfirmation() {
      this.showModalConfirmation = false;
      this.confirmationData = null;
    },
    async onConfirm() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Konfirmasi ?",
        "is-success"
      );
      if (!confirm) return;
      this.isLoadingSubmit = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          // endPoint: "loan-installment",
          endPoint: "loan",
          // reqUrl: "remodify-confirmation-upload-installment-recondition/" + this.confirmationData.loanAccountId,
          reqUrl: "loan-installment/remodify-confirmation-upload-installment/" + this.confirmationData.loanAccountId,
        });
        if (resp.data.code === "SUCCESS") {
          this.closeModalConfirmation();
          await this.simpleWait(700);
          this.$buefy.toast.open(this.$NotificationUtils.success);
          this.$emit("onfinish");
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.isLoadingSubmit = false;
      }
    },
    // async onUploadInstallment() {
    //   if (window.navigator.onLine === false) {
    //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
    //     return;
    //   }
    //   let formData = new FormData();
    //   formData.append("file", this.file);
    //   const confirmation = await this.simpleConfirmation(
    //     "Upload File Angsuran Recondition ?",
    //     "is-success"
    //   );
    //   if (confirmation) {
    //     this.isLoadingUpload = true;
    //     try {
    //       const resp = await this.$store.dispatch({
    //         type: "POST_DATA_FILE",
    //         endPoint: "loan",
    //         reqUrl: "loan-installment/remodify-upload-installment-recondition",
    //         payload: formData,
    //       });
    //       if (resp.data.code === "SUCCESS") {
    //         this.closeModalUpload();
    //         this.confirmationData = resp.data.data;
    //         this.showModalConfirmation = true;
    //       } else {
    //         this.$buefy.dialog.alert({
    //           title: "Gagal !",
    //           message: resp.data.errorFields
    //             ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
    //             : resp.data.message,
    //           confirmText: "Ok",
    //           type: "is-danger",
    //         });
    //       }
    //     } catch (error) {
    //       this.$buefy.dialog.alert({
    //         title: "Gagal !",
    //         message: error.response
    //           ? error.response.data.errorFields
    //             ? this.globalNotificationErrorFieldToText(
    //                 error.response.data.errorFields
    //               )
    //             : error.response.statusText
    //           : "Terjadi Kesalahan",
    //         confirmText: "Ok",
    //         type: "is-danger",
    //       });
    //     } finally {
    //       this.isLoadingUpload = false;
    //     }
    //   }
    // },
    async onUploadInstallment() {
      const loanAccountId = sessionStorage.getItem("LOAN_ACCOUNT_ID")
      console.log('loanAccountId', loanAccountId)

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      const confirmation = await this.simpleConfirmation(
        "Upload File Angsuran Recondition ?",
        "is-success"
      );
      if (confirmation) {
        this.isLoadingUpload = true;
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA_FILE",
            reqUrl:
              "loan-installment/remodify-upload-installment/" + loanAccountId,
            endPoint: "loan",
            payload: formData,
          });

          console.log("1");
          if (resp.data.code === "SUCCESS") {
            this.closeModalUpload();
            this.confirmationData = resp.data.data;
            this.showModalConfirmation = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          this.isLoadingUpload = false;
        }
      }
    },
  },
};
</script>

<style>
.loan-installment-upload-form-font {
  font-size: 11px;
}
</style>
