import "jspdf-autotable";
import LoanInstallmentReconditionUploadForm from "@/components/page/loan-installment-recondition-upload-form";


export default {
  name: "DetailHistoryAngsuranForRecondition",
  components: {
    LoanInstallmentReconditionUploadForm,
  },
  data() {
    return {
      property: {
        filterDto: {
          loanAccountNumber: "",
          nominalPokok: "",
          nominalMargin: "",
        },
        listElement: {
          historyAngsuran: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        modal: {
          showModalPreview: false,
        },
        animation: {
          isDownloadingFile: false,
        },
      },
      dataForm: {
        historyAngsuran: "",
        totalAngsuranPokok: 0,
        totalAngsuranMargin: 0,
        isComaDelimiter: false,
        loanAccountId: "",
      },
      table: {
        data: {
          historyAngsuran: [],
        },
      },
    };
  },
  methods: {
    async onDownloadTemplateUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        loanAccountId: this.dataForm.loanAccountId,
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/remodify-upload-installment",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Template_Upload_Angsuran_${this.dataForm.loanAccountId}.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadTemplateIdn(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        loanAccountId: this.dataForm.loanAccountId,
        isComaDelimiter: false,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/remodify-upload-installment",
          payload: payload,
        });
        console.log("test download template", resp);
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Template_Upload_Angsuran_${this.dataForm.loanAccountId}.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async handleAddInstallmentManual() {
      const inputPokok = this.property.filterDto.nominalPokok;
      const spanPokok = document.querySelector("#spanNominalPokok");

      if (inputPokok !== 0) {
        const data = {
          nominalPrincipal: this.property.filterDto.nominalPokok,
          nominalMargin: this.property.filterDto.nominalMargin,
          loanAccountNumber: this.property.filterDto.loanAccountNumber,
          nominal: 0,
          documentProofNumber: 0,
          description: "",
        };

        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        } else {
          this.$buefy.dialog.confirm({
            message: "Simpan Data Ini ?",
            type: "is-success",
            onConfirm: async () => {
              try {
                const resp = await this.$store.dispatch({
                  type: "POST_DATA",
                  endPoint: "loan",
                  reqUrl: "loan-installment/recondition-payment",
                  payload: data,
                });
                setTimeout(() => {
                  if (resp.data.code === "SUCCESS") {
                    const toastBuefy = this.$buefy.toast.open(
                      this.$NotificationUtils.success
                    );
                    toastBuefy.$on("close", () => {
                      window.location.reload();
                    });
                  } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                    this.$buefy.dialog.alert({
                      title: "Harap Tunggu !",
                      message: resp.data.errorFields
                        ? this.globalNotificationErrorFieldToText(
                            resp.data.errorFields
                          )
                        : resp.data.message,
                      confirmText: "Ok",
                      type: "is-danger",
                    });
                  } else {
                    this.$buefy.dialog.alert({
                      title: "Gagal !",
                      message: `${resp.data.message}`,
                      confirmText: "Ok",
                      type: "is-danger",
                    });
                  }
                }, 1500);
              } catch (error) {
                setTimeout(() => {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: error.response
                      ? `${error.response.statusText}`
                      : "Terjadi Kesalahan",
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }, 1500);
              } finally {
                setTimeout(() => {}, 1000);
              }
            },
          });
        }
      } else {
        spanPokok.style.visibility = "visible";
      }
    },
    onUploadLoanInstallment() {
      this.$refs.loanInstallmentReconditionUploadFormTRef.showModalUpload = true;
    },
    handleCatchErrorHistoryAngsuran(error) {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getHistoryInstallmentData() {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.downloading = true;
      this.dataForm.historyAngsuran = "";
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListHistoryAngsuran();
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "loan-installment/history/" +
              this.property.filterDto.loanAccountNumber,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.dataForm.historyAngsuran = resp.data.data;
              this.dataForm.loanAccountId = resp.data.data.loanAccountId;
              if (
                this.dataForm.historyAngsuran
                  .tunggakanMarginSampaiBulanBerjalan < 0
              ) {
                this.dataForm.historyAngsuran.tunggakanMarginSampaiBulanBerjalan = 0;
              }

              for (
                let i = 0;
                i < this.dataForm.historyAngsuran.detailInstallment.length;
                i++
              ) {
                this.dataForm.totalAngsuranPokok =
                  this.dataForm.totalAngsuranPokok +
                  this.dataForm.historyAngsuran.detailInstallment[i].principal;
                this.dataForm.totalAngsuranMargin =
                  this.dataForm.totalAngsuranMargin +
                  this.dataForm.historyAngsuran.detailInstallment[i].margin;
              }
            } else {
              this.property.listElement.historyAngsuran.message =
                resp.data.message;
            }
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.handleCatchErrorHistoryAngsuran(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.historyAngsuran.downloading = false;
          }, timeout);
        }
      }
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_NUMBER");
      if (getTokenFromSession) {
        this.property.filterDto.loanAccountNumber = getTokenFromSession;
        this.getHistoryInstallmentData();
      }
    },
    routeToPageListHistoryAngsuran() {
      this.$router.push("list");
    },
    destroySession() {
      sessionStorage.removeItem("LOAN_ACCOUNT_NUMBER");
    },
  },
  mounted() {
    this.getIdentityFromSession();
  },
  destroyed() {
    this.destroySession();
  },
};
